import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../../../../../Components/Header/Header';
import HeaderLower from '../../../../../Components/HeaderLower/HeaderLower';
import Select from 'react-select';


const EditarDomiclio = () => {
    const location = useLocation();
    const token = localStorage.getItem('token');
    const [tipoDireccion, setTipoDireccion] = useState("Envío");
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('domicilio');
    const [datosDomicilio, setDatosDomicilio] = useState({
        direccion: '',
        fk_idprov: 1,
        codpost: '',
        fk_idpais: 0,
        dpto: '',
        piso: '',
        numero_celular: '',
        calle: '',
        alias: '',
        entre_calles: '',
        alternativa: '',
        descripcion: '',
        email: '',
        retiro: '',
        fk_idtipodoc_persona: 7,
        documento_personal: '',
        nombre: '',
        apellido: '',
        fk_idlocalidad: 1,
        numero_celular: ''
    });

    const handleChange = (e) => {
        setDatosDomicilio({
            ...datosDomicilio,
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'fk_idprov') {
            setLocalidades([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: e.target.value }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
            }
            fetchLocalidades();
        }
    };


    useEffect(() => {
        const getDatos = async () => {
            const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/datos-usuario', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ token, iddomicilio: query }),
                isBase64Encoded: false,
            });
            const datosResponse = JSON.parse(response.data.body)[0];
            setTipoDireccion(datosResponse.tipo)
            setDatosDomicilio({
                nombre: datosResponse.nombre,
                apellido: datosResponse.apellido,
                email: datosResponse.email,
                documento_personal: datosResponse.documento_personal,
                direccion: datosResponse.direccion,
                fk_idprov: datosResponse.fk_idprov,
                fk_idlocalidad: datosResponse.fk_idlocalidad,
                codpost: datosResponse.codpost,
                fk_idpais: datosResponse.fk_idpais,
                dpto: datosResponse.dpto,
                piso: datosResponse.piso,
                numero_celular: datosResponse.numero_celular,
                calle: datosResponse.calle,
                alias: datosResponse.alias,
                entre_calles: datosResponse.entre_calles,
                alternativa: datosResponse.alternativa,
                descripcion: datosResponse.observaciones,
                retiro: datosResponse.retiro,
                fk_idtipodoc_persona: datosResponse.fk_idtipodoc_persona,
                numero_celular: datosResponse.numero_celular
            });
            setTipoDireccion(datosResponse.tipo);
            const fetchProvincias = async () => {
                const response = await fetch('https://api.depcsuite.com/td/getProvincias?id=12', {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.json();
                setProvincias(data);
            }
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: datosResponse.fk_idprov }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
            }
            fetchLocalidades();
            fetchProvincias();
        }
        getDatos();
    }, []);

    const mapaLocalidades = {};
    localidades.forEach(localidad => {
        mapaLocalidades[localidad.idlocalidad] = localidad.nombre;
    });

    const mapaProvincias = {};
    localidades.forEach(provincia => {
        mapaProvincias[provincia.idprov] = provincia.nombre;
    });

    const handleSubmit = async () => {
        datosDomicilio.alternativa = datosDomicilio.alternativa || datosDomicilio.direccion + ', ' + mapaLocalidades[datosDomicilio.fk_idlocalidad] + ', ' + mapaProvincias[datosDomicilio.fk_idprov];
        const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/editar-datos-domicilio', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ ...datosDomicilio, token, tipoDireccion, iddomicilio: query }),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response?.data?.body)
        if (!data.error) {
            window.location.href = '/mis-datos'
        }
    }

    const handleChangeLocalidad = (newValue, fieldName) => {
        setDatosDomicilio({
            ...datosDomicilio,
            [fieldName]: newValue
        });
        if (fieldName === 'fk_idprov') {
            setLocalidades([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: newValue }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
            }
            fetchLocalidades();
        }
    };

    const options = localidades.map((localidad) => ({
        value: localidad.idlocalidad,
        label: localidad.nombre
    }));
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '49px', // Ajusta la altura mínima
            fontSize: '16px', // Ajusta el tamaño de la fuente
        }),
        // Otros estilos personalizados que desees agregar
    };
    const MySelect = ({ value, onChange }) => (
        <div className='w-full mt-5 rounded-md'>
            <Select
                options={options}
                value={options.find(option => option.value === value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                placeholder="Localidad*"
                isSearchable={true}
                styles={customStyles}
                classNamePrefix="react-select"
            />
        </div>
    );

    return (
        <div>
            <Header />
            <HeaderLower />
            <div className='flex flex-col pt-20 justify-center'>
                <div className='bg-[#EFEFED] w-full lg:pt-10 pt-5'>
                    <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                        <p className='text-sm lg:block hidden text-left lg:ml-16 ml-10 font-semibold'><a className='font-normal' href="/">Home {'>'}</a> Login</p>
                        <p className='text-5xl my-10 lg:pb-20 pb-10'>
                            Mis datos
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col justify-start lg:mr-20 mx-10 lg:mx-40 mt-10'>
                <p className='font-semibold text-lg text-left'>Editar domicilio</p>
                <input onChange={handleChange} value={datosDomicilio?.alternativa} type="text" name='alternativa' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Nombre completo de quien recibe' />
                <div className='flex'>
                    <div className='w-1/2'>
                        <select name='fk_idprov' onChange={handleChange} required value={datosDomicilio?.fk_idprov} type="text" className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Provincia*'>
                            {provincias.map((provincia) => (
                                <option key={provincia.idprov} value={provincia.idprov}>{provincia.descprov}</option>
                            ))}
                        </select>
                    </div>
                    <div className='w-1/2 ml-3'>
                        <MySelect value={datosDomicilio?.fk_idlocalidad} onChange={(newValue) => handleChangeLocalidad(newValue, 'fk_idlocalidad')} />
                    </div>
                </div>
                <div className='flex flex-row'>
                    <input
                        type="text"
                        name='codpost'
                        className='w-1/2 border mr-3 pl-4 py-3 mt-5 rounded-md'
                        onChange={handleChange}
                        placeholder='Código postal'
                    />
                    <input type="text" name='direccion' onChange={handleChange} value={datosDomicilio?.direccion} className='w-1/2 border pl-4 py-3 mt-5 rounded-md' placeholder='Dirección (calle y número)' />
                </div>
                <div className='flex flex-row '>
                    <select
                        type="text"
                        name='fk_idtipodoc_personal'
                        onChange={handleChange}
                        value={datosDomicilio?.fk_idtipodoc_persona}
                        className='w-1/2 border pl-4 py-3 mt-5 rounded-md'
                        placeholder='DNI'
                    >
                        <option value="7">DNI</option>
                        <option value="13">OTRO</option>
                    </select>
                    <input
                        onChange={handleChange}
                        type="text"
                        value={datosDomicilio?.documento_personal}
                        name='documento_personal'
                        className='w-1/2 border ml-3 pl-4 py-3 mt-5 rounded-md'
                        placeholder='Número de documento'
                    />
                </div>
                <div className='flex flex-row '>
                    <input
                        type="text"
                        name='piso'
                        value={datosDomicilio?.piso}
                        onChange={handleChange}
                        className='w-1/2 border pl-4 py-3 mt-5 rounded-md'
                        placeholder='Piso'
                    />
                    <input
                        onChange={handleChange}
                        type="text"
                        name='dpto'
                        value={datosDomicilio?.dpto}
                        className='w-1/2 border ml-3 pl-4 py-3 mt-5 rounded-md'
                        placeholder='Departamento'
                    />
                </div>
                <input onChange={handleChange} value={datosDomicilio?.entre_calles} type="text" name='entre_calles' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Entre calles' />
                <input onChange={handleChange} value={datosDomicilio?.numero_celular} type="text" name='numero_celular' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Teléfono de contacto' />
                <input
                    onChange={handleChange}
                    type="text"
                    value={datosDomicilio?.descripcion}
                    name='descripcion'
                    className='w-full border pl-4 pb-40  pt-2 mt-5 rounded-md'
                    placeholder='Notas para la entrega:'
                />
                <div className='flex lg:flex-row flex-col justify-between '>
                    <div className='flex flex-row text-left text-[16px] opacity-90'>
                        <input
                            className='mr-2 w-4'
                            type="radio"
                            name="tipoDireccion"
                            value="Envío"
                            checked={tipoDireccion === "Envío"}
                            onChange={(e) => setTipoDireccion(e.target.value)}
                        />
                        <p>Establecer como Dirección de Envío</p>
                    </div>
                    <div className='flex flex-row text-left mt-3 lg:mt-0 text-[16px] opacity-90'>
                        <input
                            className='mr-2 w-4'
                            type="radio"
                            name="tipoDireccion"
                            value="Facturación"
                            checked={tipoDireccion === "Facturación"}
                            onChange={(e) => setTipoDireccion(e.target.value)}
                        />
                        <p>Establecer como Dirección de Facturación</p>
                    </div>
                </div>
                <button
                    onClick={handleSubmit}
                    className='w-full mb-32 bg-black border pl-4 py-3 hover:bg-[#E40F1B] text-white mt-5 rounded-md'
                >GUARDAR DIRECCIÓN</button>
            </div>
        </div>
    );
};

export default EditarDomiclio;