import './FeaturedProduct.css';
import Card from '../Card/Card.jsx'
import arrowRight from '../../assets/_arrow_thin_left.svg';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDestacadas, getMoneyChange } from '../../redux/actions';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import jwt from 'jsonwebtoken';
import Modal from '../../ui/Modal/Modal.jsx';
import CardMobile from '../CardMobile/CardMobile.jsx';

const JWT_SECRET = 'tu_clave_secreta';

const FeaturedProduct = ({ isWhite = false, productOffert = '', ofertaAviso, imagenOferta }) => {
    const dispatch = useDispatch()
    const cards = useSelector((state) => state.allDestacadas);
    const [carrito, setCarrito] = useState(false);
    const swiperRef = useRef(null);
    const destacado = productOffert ? productOffert : '¡Productos destacados!';
    const dolar = useSelector((state) => state.dolarPrice);
    const token = localStorage.getItem('token');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
        setIsOpen: '',
        item: '',
        carrito: '',
        setCarrito: '',
        precioFinal: '',
        precioConIncremento: '',
        precioTachado: '',
        porcentaje: '',
    });

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwt.verify(token, JWT_SECRET);
                setAdmin(decodedToken.permisos);
            } catch (error) {
                console.error('Token no válido o caducado:', error.message);
            }
        }
    }, [token]);

    useEffect(() => {
        dispatch(getMoneyChange());
        dispatch(getDestacadas());
    }, []);

    return (
        <div className={`${isWhite ? 'bg-white' : 'bg-[#F9F9FB]'} flex flex-col`}>
            <div className='2xl:ml-[14%] 2xl:mr-[10%]'>

                <div className='flex flex-row mt-5 justify-between lg:mx-10 mx-2'>
                    {!imagenOferta ? <div className={`${productOffert ? 'text-lg' : 'lg:text-2xl lg:font-normal font-semiBoldQuicksand text-base'} font-medium lg:ml-4 mt-10`}>
                        <p className={productOffert ? 'whitespace-nowrap font-semibold' : ''}>{productOffert ? productOffert : destacado}</p>
                    </div>
                        :
                        <div className='flex flex-row'>
                            <p className=''>{productOffert}</p>
                        </div>
                    }
                    <div className='flex mt-7 lg:ml-[63%] hover:cursor-pointer' onClick={() => swiperRef.current?.swiper.slideNext()}>
                        <img className="w-8" src={arrowRight} alt="Icono" />
                    </div>

                </div>
                <div className="lg:mx-9 lg:block hidden mx-2 pt-10 mb-14">
                    <div className="">
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={90}
                            slidesPerView={4}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}

                        >
                            {cards && cards.map((card) => (
                                <SwiperSlide key={card.idproducto}>
                                    <div className=''>
                                        <Card
                                            imageUrl={card.imagen}
                                            nombre={card.nombre}
                                            price={card.precio_pesos}
                                            item={card}
                                            label={'20 off'}
                                            carrito={carrito}
                                            setCarrito={setCarrito}
                                            priceOffert={card.precio_oferta}
                                            dolar={dolar}
                                            isHome={true}
                                            admin={admin}
                                            isHomeOrDestacada={true}
                                            setDataModal={setModal}
                                            isOpenModal={isOpenModal}
                                            setIsOpenModal={setIsOpenModal}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <div className="lg:mx-9 lg:hidden mx-2 pt-10 mb-14">
                    <div className="">
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={20}
                            slidesPerView={2}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}

                        >
                            {cards && cards.map((card) => (
                                <SwiperSlide key={card.idproducto}>
                                    <div className=''>
                                        <CardMobile
                                            imageUrl={card.imagen}
                                            nombre={card.nombre}
                                            price={card.precio_pesos}
                                            item={card}
                                            label={'20 off'}
                                            carrito={carrito}
                                            setCarrito={setCarrito}
                                            priceOffert={card.precio_oferta}
                                            dolar={dolar}
                                            isHome={true}
                                            admin={admin}
                                            isHomeOrDestacada={true}
                                            setDataModal={setModal}
                                            isOpenModal={isOpenModal}
                                            setIsOpenModal={setIsOpenModal}
                                            isFeatured={true}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                {isOpenModal && <Modal
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    item={modal.item}
                    carrito={modal.carrito}
                    setCarrito={modal.setCarrito}
                    precioFinal={modal.precioFinal}
                    precioConIncremento={modal.precioConIncremento}
                    precioTachado={modal.precioTachado}
                    porcentaje={modal.porcentaje}
                />}
            </div>
        </div>
    );
}

export default FeaturedProduct;