import './App.css';
import Header from './Components/Header/Header';
import HeaderLower from './Components/HeaderLower/HeaderLower';
import NewsletterComponent from './Components/NewsletterComponent/NewsletterComponent';
import Footer from './Components/Footer/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Puffs from './pages/Puffs/puffs';
import DetallesProducto from './pages/Products/products';
import Home from './pages/Home/home';
import Mesas from './pages/Mesas/mesas';
import Deco from './pages/Deco/deco';
import Exterior from './pages/Exterior/exterior';
import Sillas from './pages/Sillas/sillas';
import Otros from './pages/Otros/Otros';
import Entrega from './pages/entrega-inmediata/entrega-inmediata';
import Collection from './pages/Collection/Collection';
import Sale from './pages/Sale/Sale';
import Set from './pages/Set/Set';
import Buscador from './pages/Buscador/buscador';
import Registro from './pages/Usuarios/Registro/Registro';
import Login from './pages/Usuarios/Login/Login';
import Recuperar from './pages/Usuarios/Recuperar/Recuperar';
import MisDatos from './pages/Usuarios/Mis-datos/MisDatos';
import CarritoPage from './pages/Carrito/carrito';
import Atencion from './pages/Atencion/Atencion';
import Cambiar from './pages/Usuarios/Cambiar/cambiar';
import Nosotros from './pages/Nosotros/Nosotros';
import { initMercadoPago } from '@mercadopago/sdk-react';
import logo from '../src/assets/logo-horizontal-ok-removebg-preview.png'
import FooterMobile from './Components/FooterMobile/FooterMobile';
import Shop from './pages/Shop/Shop';
import EditarDomiclio from './pages/Usuarios/Mis-datos/Datos/editar-domicilio/editar-domicilio';

initMercadoPago("APP_USR-27de1c87-5dbc-4bf1-b630-aca8e0a263f6");
/**
 * Renders the main application component.
 * @returns {JSX.Element} The rendered application component.
 */
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/registrarse'>
            <Registro />
          </Route>
          <Route path='/carrito'>
            <CarritoPage />
          </Route>
          <Route path='/atencion-al-cliente/:inicio'>
            <Atencion />
          </Route>
          <Route path='/nosotros'>
            <Nosotros />
          </Route>
          <Route path='/recuperar'>
            <Recuperar />
          </Route>
          <Route path='/cambiar-clave/:token'>
            <Cambiar />
          </Route>
          <Route exact path='/mis-datos/editar-domicilio'>
            <EditarDomiclio />
          </Route>
          <Route exact path='/mis-datos/:fav'>
            <MisDatos favorito={true} />
          </Route>
          <Route exact path='/mis-datos'>
            <MisDatos />
          </Route>
          <Route path='/ingresar'>
            <Login />
          </Route>
          <Route exact path='/buscador'>
            <Buscador />
          </Route>
          <Route exact path='/set-de-productos'>
            <Set />
          </Route>
          <Route exact path='/entrega-inmediata'>
            <Entrega />
          </Route>
          <Route exact path='/sale'>
            <Sale />
          </Route>
          <Route exact path='/collection'>
            <Collection />
          </Route>
          <Route exact path='/mesas'>
            <Mesas />
          </Route>
          <Route path='/mesas/:tipoDeProducto'>
            <Mesas />
          </Route>
          <Route exact path='/exterior'>
            <Exterior />
          </Route>
          <Route path='/exterior/:tipoDeProducto'>
            <Exterior />
          </Route>
          <Route exact path='/sillas'>
            <Sillas />
          </Route>
          <Route path='/sillas/:tipoDeProducto'>
            <Sillas />
          </Route>
          <Route exact path='/otros'>
            <Otros />
          </Route>
          <Route path='/otros/:tipoDeProducto'>
            <Otros />
          </Route>
          <Route exact path='/deco-iluminaciones'>
            <Deco />
          </Route>
          <Route path='/deco-iluminaciones/:tipoDeProducto'>
            <Deco />
          </Route>
          <Route exact path='/puffs-sillones'>
            <Puffs />
          </Route>
          <Route path='/puffs-sillones/:tipoDeProducto'>
            <Puffs />
          </Route>
          <Route path='/shop/:tipoDeProducto'>
            <Shop />
          </Route>
          <Route exact path='/shop'>
            <Shop />
          </Route>
          <Route path='/producto/:paramsSlug'>
            <DetallesProducto />
          </Route>
        </Switch>
      </Router>
      <NewsletterComponent />
      <div className='lg:block hidden'>
        <Footer />
      </div>
      <div className='lg:hidden'>
        <FooterMobile />
      </div>
    </div>
  );
}

export default App;
