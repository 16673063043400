import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const AgregarDomiclio = () => {
    const token = { token: localStorage.getItem('token') };
    const [tipoDireccion, setTipoDireccion] = useState("Envío");
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [datosDomicilio, setDatosDomicilio] = useState({
        direccion: '',
        fk_idprov: 1,
        codpost: '',
        fk_idpais: 12,
        dpto: '',
        piso: '',
        numero_celular: '',
        calle: '',
        alias: '',
        entre_calles: '',
        alternativa: '',
        descripcion: '',
        fk_idtipodoc_personal: 7,
        documento_personal: '',
        fk_idlocalidad: 1
    })

    const handleChange = (e) => {
        setDatosDomicilio({
            ...datosDomicilio,
            [e.target.name]: e.target.value
        });
        if (e.target.name === 'fk_idprov') {
            setLocalidades([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: e.target.value }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
            }
            fetchLocalidades();
        }
    };

    const handleChangeLocalidad = (newValue, fieldName) => {
        setDatosDomicilio({
            ...datosDomicilio,
            [fieldName]: newValue
        });
        if (fieldName === 'fk_idprov') {
            setLocalidades([]);
            const fetchLocalidades = async () => {
                const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                    httpMethod: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    queryStringParameters: {},
                    pathParameters: {},
                    body: JSON.stringify({ fk_idprov: newValue }),
                    isBase64Encoded: false,
                });
                const response = JSON.parse(responseLocalidades?.data?.body)
                setLocalidades(response);
            }
            fetchLocalidades();
        }
    };


    useEffect(() => {
        const fetchProvincias = async () => {
            const response = await fetch('https://api.depcsuite.com/td/getProvincias?id=12', {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            setProvincias(data);
        }
        const fetchLocalidades = async () => {
            const responseLocalidades = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/conseguir-localidades', {
                httpMethod: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                queryStringParameters: {},
                pathParameters: {},
                body: JSON.stringify({ fk_idprov: datosDomicilio?.fk_idprov }),
                isBase64Encoded: false,
            });
            const response = JSON.parse(responseLocalidades?.data?.body)
            setLocalidades(response);
        }
        fetchLocalidades();
        fetchProvincias();
    }, []);

    const mapaLocalidades = {};
    localidades.forEach(localidad => {
        mapaLocalidades[localidad.idlocalidad] = localidad.nombre;
    });

    const mapaProvincias = {};
    provincias.forEach(provincia => {
        mapaProvincias[provincia.idprov] = provincia.descprov;
    });

    const handleSubmit = async () => {
        datosDomicilio.alternativa = datosDomicilio.direccion + ', ' + mapaLocalidades[datosDomicilio.fk_idlocalidad] + ', ' + mapaProvincias[datosDomicilio.fk_idprov ? datosDomicilio.fk_idprov : 1];
        const response = await axios.post('https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce/agregar-domicilio', {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ ...datosDomicilio, ...token, tipoDireccion }),
            isBase64Encoded: false,
        });
        const data = JSON.parse(response?.data?.body)
        if (data.error) {
            window.localStorage.removeItem('token');
            window.location.href = '/ingresar';
        }
        if (data.message) {
            window.location.href = '/mis-datos';
        }
    }

    const options = localidades.map((localidad) => ({
        value: localidad.idlocalidad,
        label: localidad.nombre
    }));
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '49px', // Ajusta la altura mínima
            fontSize: '16px', // Ajusta el tamaño de la fuente
        }),
        // Otros estilos personalizados que desees agregar
    };
    const MySelect = ({ value, onChange }) => (
        <div className='w-full mt-5 rounded-md'>
            <Select
                options={options}
                value={options.find(option => option.value === value)}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                placeholder="Localidad*"
                isSearchable={true}
                styles={customStyles}
                classNamePrefix="react-select"
            />
        </div>
    );
    return (
        <div className='flex flex-col justify-start lg:mr-20 mx-10 lg:mx-0'>
            <p className='font-semibold text-lg text-left'>Agregar nueva dirección</p>
            <input onChange={handleChange} type="text" name='alternativa' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Nombre completo de quien recibe' />
            <div className='flex'>
                <div className='w-1/2'>
                    <select name='fk_idprov' onChange={handleChange} required value={datosDomicilio?.fk_idprov} type="text" className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Provincia*'>
                        {provincias.map((provincia) => (
                            <option key={provincia.idprov} value={provincia.idprov}>{provincia.descprov}</option>
                        ))}
                    </select>
                </div>
                <div className='w-1/2 ml-3'>
                    <MySelect value={datosDomicilio?.fk_idlocalidad} onChange={(newValue) => handleChangeLocalidad(newValue, 'fk_idlocalidad')} />
                </div>
            </div>
            <div className='flex flex-row'>
                <input
                    type="text"
                    name='codpost'
                    className='w-1/2 border mr-3 pl-4 py-3 mt-5 rounded-md'
                    onChange={handleChange}
                    placeholder='Código postal'
                />
                <input type="text" name='direccion' onChange={handleChange} className='w-1/2 border pl-4 py-3 mt-5 rounded-md' placeholder='Dirección (calle y número)' />
            </div>
            <div className='flex flex-row '>
                <select
                    type="text"
                    name='fk_idtipodoc_personal'
                    onChange={handleChange}
                    className='w-1/2 border pl-4 py-3 mt-5 rounded-md'
                    placeholder='DNI'
                >
                    <option value="7">DNI</option>
                    <option value="13">OTRO</option>
                </select>
                <input
                    onChange={handleChange}
                    type="text"
                    name='documento_personal'
                    className='w-1/2 border ml-3 pl-4 py-3 mt-5 rounded-md'
                    placeholder='Número de documento'
                />
            </div>
            <div className='flex flex-row '>
                <input
                    type="text"
                    name='piso'
                    onChange={handleChange}
                    className='w-1/2 border pl-4 py-3 mt-5 rounded-md'
                    placeholder='Piso'
                />
                <input
                    onChange={handleChange}
                    type="text"
                    name='dpto'
                    className='w-1/2 border ml-3 pl-4 py-3 mt-5 rounded-md'
                    placeholder='Departamento'
                />
            </div>
            <input onChange={handleChange} type="text" name='entre_calles' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Entre calles' />
            <input onChange={handleChange} type="text" name='numero_celular' className='w-full border pl-4 py-3 mt-5 rounded-md' placeholder='Teléfono de contacto' />
            <input
                onChange={handleChange}
                type="text"
                name='descripcion'
                className='w-full border pl-4 pb-40  pt-2 mt-5 rounded-md'
                placeholder='Notas para la entrega:'
            />
            <div className='flex lg:flex-row flex-col justify-between '>
                <div className='flex flex-row text-left text-[16px] opacity-90'>
                    <input
                        className='mr-2 w-4'
                        type="radio"
                        name="tipoDireccion"
                        value="Envío"
                        checked={tipoDireccion === "Envío"}
                        onChange={(e) => setTipoDireccion(e.target.value)}
                    />
                    <p>Establecer como Dirección de Envío</p>
                </div>
                <div className='flex flex-row text-left mt-3 lg:mt-0 text-[16px] opacity-90'>
                    <input
                        className='mr-2 w-4'
                        type="radio"
                        name="tipoDireccion"
                        value="Facturación"
                        checked={tipoDireccion === "Facturación"}
                        onChange={(e) => setTipoDireccion(e.target.value)}
                    />
                    <p>Establecer como Dirección de Facturación</p>
                </div>
            </div>
            <button
                onClick={handleSubmit}
                className='w-full mb-32 bg-black border pl-4 py-3 hover:bg-[#E40F1B] text-white mt-5 rounded-md'
            >GUARDAR DIRECCIÓN</button>
        </div>
    );
};

export default AgregarDomiclio;