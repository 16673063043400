import React, { useEffect, useState } from 'react';
import { getDestacadas, getMoneyChange, getProduct } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../Components/Header/Header';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import home1 from '../../assets/HOME-1-1920x886-puff-beige.png';
import home2 from '../../assets/HOME-2-1920x886-sillas-transparentes.jpg';
import homeSaleMobile from '../../assets/portada-HotSale-HOME-Mobile.gif';
import homeSale from '../../assets/portada-HotSale-HOME.gif';
import home3 from '../../assets/HOME-3-1920x886-sillas-madera.jpg';
import homeCategorySillas from '../../assets/HOME-CATEGORIAS-459x459-SILLAS.png';
import homeCategoryPuffs from '../../assets/HOME-CATEGORIAS-777x459-PUFF.png'
import homeCategoryMesas from '../../assets/HOME-CATEGORIAS-777x459-MESAS.png'
import homeCategoryDeco from '../../assets/HOME-CATEGORIAS-459x459-DECO.png'
import homeOutlet from '../../assets/HOME-OUTLET-441x539.png'
import arrow from '../../assets/_arrow_thin_left.svg';
import design from '../../assets/_disenos.svg';
import security from '../../assets/_seguridad.svg';
import cuotas from '../../assets/_cuotas.svg';
import envios from '../../assets/_envios.svg';
import entregaMobile from '../../assets/img-entrega-mobile.png';
import descuentos from '../../assets/_descuentos.svg';
import entrgaLogo from '../../assets/ENTREGA-LOGOS-1920x935.png';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import foltanteWhatsapp from '../../assets/flotante_whatsapp.svg';
import foltanteWhatsappHover from '../../assets/flotante_whatsapp_hover.svg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import Card from '../../Components/Card/Card';
import jwt from 'jsonwebtoken';
import Modal from '../../ui/Modal/Modal';

const JWT_SECRET = 'tu_clave_secreta';

function Home() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const errorQuery = searchParams.get('isredirect');
    const [selectSlider, setSelectSlider] = useState(0);
    const token = useSelector(state => state.token);
    const [carrito, setCarrito] = useState(false);
    const [fade, setFade] = useState(true);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [errorPago, setErrorPago] = useState(true)
    const [hoverWSP, setHoverWSP] = useState(false);
    const dispatch = useDispatch();
    const cards = useSelector((state) => state.allPriceFilter);
    const dolar = useSelector((state) => state.dolarPrice);
    const [admin, setAdmin] = useState(false);
    const [modal, setModal] = useState({
        isOpen: false,
        setIsOpen: '',
        item: '',
        carrito: '',
        setCarrito: '',
        precioFinal: '',
        precioConIncremento: '',
        precioTachado: '',
        porcentaje: ''
    });

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwt.verify(token, JWT_SECRET);
                setAdmin(decodedToken.permisos);
            } catch (error) {
                console.error('Token no válido o caducado:', error.message);
            }
        }
    }, [token]);

    useEffect(() => {
        dispatch(getProduct([], undefined, 'SALE'));
    }, []);

    const titulos = [
        {
            titulo: 'Diseños Importados',
            subtitulo: `Contamos con la mejor version de los productos de los
            diseñadores más prestigiosos del mundo.`,
            homeImg: home2,
            link: '/puffs-sillones'
        },
        {
            titulo: 'Diseños de Producción Nacional',
            subtitulo: `Descubri los productos que diseñamos con el sello propio para
            que luzcas a través de un gran impacto visual.`,
            homeImg: home3,
            link: '/sillas'
        },
        {
            titulo: 'Descuentos & Promociones',
            subtitulo: `Encontrá una selección minuciosa de productos.`,
            homeImg: home1,
            link: '/mesas'
        }
    ];

    useEffect(() => {
        const sliderInterval = setInterval(() => {
            setFade(false);

            setTimeout(() => {
                setSelectSlider((prevSelectSlider) => (prevSelectSlider + 1) % titulos.length);
                setFade(true);
            }, 500);
        }, 5000);

        return () => clearInterval(sliderInterval);
    }, []);

    return (
        <div>
            {cards && <div>
                <Header />
                <HeaderLower isTransparent={true} />
                {!hoverWSP ? <button onMouseEnter={() => setHoverWSP(true)} className='fixed bottom-0 right-5 z-[1000]'>
                    <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                        <img src={foltanteWhatsapp} className='mb-5' alt="" />
                    </a>
                </button> :
                    <button onMouseLeave={() => setHoverWSP(false)} className='fixed bottom-0 right-5 z-[1000]'>
                        <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                            <img src={foltanteWhatsappHover} className='mb-5' alt="" />
                        </a>
                    </button>
                }
                {errorQuery && errorPago &&
                    <div className="relative top-72">
                        <div onClick={() => setErrorPago(false)} className="fixed inset-0 bg-black bg-opacity-50 z-40" aria-hidden="true"></div>
                        <div className='fixed left-1/2 z-[300000]'>
                            <div className="fixed top-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-[300px] w-1/3 bg-white border-2 border-gray-300 shadow-lg z-50 rounded-lg p-6">
                                <div className="flex flex-col items-start">
                                    <div className="shrink-0">
                                        <div className="flex content-center items-center ml-44 mb-6 justify-center h-12 w-12 rounded-full bg-red-100">
                                            <svg className="h-6 w-6 text-[#E40F1B]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="ml-4">
                                        <p className="text-sm font-medium text-gray-900">
                                            No hay stock disponible para uno o varios productos.<br />Carrito de compra borrado.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className='lg:h-1/2 lg:hidden flex relative'>
                    <div className='absolute inset-y-0 left-0 w-1/2 bg-black' style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8), transparent)' }}></div>
                    <div className='absolute 2xl:ml-[14%] 2xl:mr-[10%] ml-1.5 lg:ml-0 lg:bottom-12 mt-96 lg:mt-0 mb-1 lg:max-h-[323px] max-h-[241px] max-w-[364px] lg:left-10 border-2 text-left bg-[#00000033] lg:pt-10 bg-opacity-50 p-5 lg:max-w-[707px] lg:pr-10 flex flex-col justify-between'>
                        <div className=''>
                            <p className={`leading-tight text-white lg:text-5xl text-[30px] lg:mt-5`}>{titulos[selectSlider].titulo}</p>
                            <p className='lg:text-[22px] text-[16px] my-2 lg:pr-14 text-white'>{titulos[selectSlider].subtitulo}</p>
                        </div>
                        <div>
                            <button className='w-28 h-9 text-[14px] rounded-sm text-white border border-white'>
                                <a href='/shop'>
                                    SHOP
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className='absolute 2xl:pl-[14%] 2xl:pr-[10%] lg:left-10 left-5 lg:bottom-5 bottom-16'>
                        <button onClick={() => {
                            setSelectSlider(0);
                        }} className={`w-14 h-0.5 ${selectSlider === 0 ? 'bg-black' : 'bg-white'}`}></button>
                        <button onClick={() => setSelectSlider(1)} className={`w-14 h-0.5 mx-4 ${selectSlider === 1 ? 'bg-black' : 'bg-white'}`}></button>
                        <button onClick={() => setSelectSlider(2)} className={`w-14 h-0.5 ${selectSlider === 2 ? 'bg-black' : 'bg-white'}`}></button>
                    </div>
                    <div className="relative -z-10 lg:w-full lg:h-full w-full h-[704px]">
                        <img src={titulos[selectSlider].homeImg} className="w-full h-full object-cover object-center duration-500" alt="Banner principal" />
                    </div>
                </div>
                <div className='lg:h-1/2 hidden lg:flex relative'>
                    <div className='absolute inset-y-0 left-0 w-1/2 bg-black' style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8), transparent)' }}></div>
                    <div className='absolute 2xl:ml-[14%] 2xl:mr-[10%] ml-1.5 lg:ml-0 lg:bottom-12 mt-96 lg:mt-0 mb-1 lg:max-h-[323px] max-h-[241px] max-w-[364px] lg:left-10 border-2 text-left bg-[#00000033] lg:pt-10 bg-opacity-50 p-5 lg:max-w-[707px] lg:pr-10 flex flex-col justify-between'>
                        <div className=''>
                            <p className={`leading-tight text-white lg:text-5xl text-[30px] lg:mt-5`}>{titulos[selectSlider].titulo}</p>
                            <p className='lg:text-[22px] text-[16px] my-2 lg:pr-14 text-white'>{titulos[selectSlider].subtitulo}</p>
                        </div>
                        <div>
                            <button className='w-28 h-9 text-[14px] rounded-sm text-white border border-white'>
                                <a href='/shop'>
                                    SHOP
                                </a>
                            </button>
                        </div>
                    </div>
                    <div className='absolute 2xl:pl-[14%] 2xl:pr-[10%] lg:left-10 left-5 lg:bottom-5 bottom-16'>
                        <button onClick={() => {
                            setSelectSlider(0)
                        }} className={`w-14 h-0.5 ${selectSlider === 0 ? 'bg-black' : 'bg-white'}`}></button>
                        <button onClick={() => setSelectSlider(1)} className={`w-14 h-0.5 mx-4 ${selectSlider === 1 ? 'bg-black' : 'bg-white'}`}></button>
                        <button onClick={() => setSelectSlider(2)} className={`w-14 h-0.5 ${selectSlider === 2 ? 'bg-black' : 'bg-white'}`}></button>
                    </div>
                    <div className="relative -z-10 lg:w-full lg:h-full w-full h-[704px]">
                        <img src={titulos[selectSlider].homeImg} className="w-full h-full object-cover object-center duration-500" alt="Banner principal" />
                    </div>
                </div>
                <div className='2xl:pl-[14%] 2xl:pr-[10%]'>
                    <div className='flex flex-col lg:mx-10 mx-3 mt-10 lg:mt-28'>
                        <div className='flex lg:flex-row flex-col'>
                            <div className='flex flex-row'>
                                <a href='/sillas' className="group relative rounded-md overflow-hidden lg:max-w-full lg:max-h-full max-w-[187px] max-h-[187px]">
                                    <img src={homeCategorySillas} className="object-cover object-center rounded-md transform group-hover:scale-110 w-full" alt="" />
                                    <div className="hidden flex-col absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 lg:flex items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                        <p className="text-3xl uppercase font-semibold">Sillas & banquetas</p>
                                        <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                    </div>
                                    <div className="absolute inset-0 group-hover:blur"></div>
                                </a>
                                <a href='/sillas' className='uppercase lg:hidden text-[20px] text-left ml-2 font-bold items-center flex justify-center'>Sillas & Banquetas</a>
                            </div>
                            <div className='lg:mx-4 flex flex-row lg:mt-0 mt-8'></div>
                            <a href='/puffs-sillones' className="hidden lg:block group relative rounded-md lg:max-w-full lg:max-h-full overflow-hidden">
                                <img src={homeCategoryPuffs} className="hidden lg:block rounded-md lg:w-full w-40 transform group-hover:scale-110" alt="" />
                                <div className="flex-col hidden absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 lg:flex items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                    <p className="font-semibold text-3xl uppercase">Puffs & sillones</p>
                                    <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                </div>
                                <div className="absolute inset-0 group-hover:blur"></div>
                            </a>
                            <div className='flex flex-row lg:hidden'>
                                <a href='/puffs-sillones' className="group max-w-[187px] h-[187px] relative rounded-md overflow-hidden">
                                    <img src={homeCategoryPuffs} className="rounded-md object-cover object-center w-full h-full" alt="" />

                                    <div className="flex-col hidden absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 lg:flex items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                        <p className="font-semibold text-3xl uppercase">Puffs & sillones</p>
                                        <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                    </div>
                                    <div className="absolute inset-0 group-hover:blur"></div>
                                </a>
                                <a href='/puffs-sillones' className='uppercase lg:hidden text-[20px] text-left ml-2 font-bold items-center flex justify-center'>Puffs & sillones</a>
                            </div>
                        </div>

                        <div className='flex lg:flex-row flex-col lg:mt-10'>
                            <a href='/mesas' className="hidden lg:block group relative rounded-md overflow-hidden">
                                <img src={homeCategoryMesas} className="rounded-md transform group-hover:scale-110" alt="" />
                                <div className="flex flex-col absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                    <p className="font-semibold uppercase text-3xl">MESAS</p>
                                    <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                </div>
                                <div className="absolute inset-0 group-hover:blur transition duration-300 ease-in-out"></div>
                            </a>
                            <div className='flex flex-row lg:hidden mt-8'>
                                <a href='/mesas' className="group w-[187px] h-[187px] relative rounded-md overflow-hidden">
                                    <img src={homeCategoryMesas} className="rounded-md object-cover object-center w-full h-full" alt="" />

                                    <div className="flex-col hidden absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 lg:flex items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                        <p className="font-semibold text-3xl uppercase">Mesas</p>
                                        <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                    </div>
                                    <div className="absolute inset-0 group-hover:blur"></div>
                                </a>
                                <a href='/mesas' className='uppercase lg:hidden text-[20px] text-left ml-2 font-bold items-center flex justify-center'>Mesas</a>
                            </div>
                            <div className='mx-4'></div>
                            <a href='/deco-iluminaciones/' className="hidden lg:block group relative rounded-md overflow-hidden">
                                <img src={homeCategoryDeco} className="rounded-md transform group-hover:scale-110" alt="" />
                                <div className="flex flex-col absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                    <p className=" text-3xl uppercase font-semibold">Deco & iluminación</p>
                                    <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                </div>
                                <div className="absolute inset-0 group-hover:blur transition duration-300 ease-in-out"></div>
                            </a>
                            <div className='flex flex-row lg:hidden mt-8'>
                                <a href='/deco-iluminaciones/' className="group max-w-[187px] max-h-[187px] relative rounded-md overflow-hidden">
                                    <img src={homeCategoryDeco} className="rounded-md object-cover object-center w-full h-full" alt="" />

                                    <div className="flex-col hidden absolute inset-0 bg-white bg-opacity-0 group-hover:bg-opacity-50 lg:flex items-center justify-center rounded-md opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out">
                                        <p className="font-semibold text-3xl uppercase">Deco & iluminación</p>
                                        <button className='bg-white px-6 py-2 rounded-md border-black border text-sm mt-10 mb-5 font-semibold'>Ver más</button>
                                    </div>
                                    <div className="absolute  inset-0 group-hover:blur"></div>
                                </a>
                                <a href='/deco-iluminaciones/' className='uppercase  lg:hidden left-2 relative text-[20px] text-left font-bold items-center flex justify-center'>Deco & iluminación</a>
                            </div>

                        </div>
                    </div>
                    <div className='mt-24 2xl:ml-10 2xl:mr-20 lg:block hidden'>
                        <div className='flex flex-row'>
                            <div className='w-1/4'>
                                <div className='relative outline-none'>
                                    <div className='absolute text-left 2xl:mr-40 lg:left-48 left-10 2xl:mt-0 mt-5 top-5'>
                                        <div className='flex flex-row'>
                                            <p className='text-xl text-white'>Outlet</p>
                                            <img src={arrow} className='h-6 ml-2 mt-1' />
                                        </div>
                                        <p className='text-lg object-contain text-white w-28'>descuentos y promociones especiales</p>
                                    </div>
                                </div>
                                <img src={homeOutlet} alt="Home Outlet" className='lg:w-[539px] g:h-full' />
                            </div>
                            <div className='w-3/4'>
                                <Swiper
                                    spaceBetween={90}
                                    slidesPerView={3}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}

                                >
                                    {cards && cards.map((card) => (
                                        <SwiperSlide key={card.idproducto}>
                                            <div className=''>
                                                <Card
                                                    imageUrl={card.imagen}
                                                    nombre={card.nombre}
                                                    price={card.precio_pesos}
                                                    item={card}
                                                    label={'20 off'}
                                                    carrito={carrito}
                                                    setCarrito={setCarrito}
                                                    priceOffert={card.precio_oferta}
                                                    dolar={dolar}
                                                    isHome={true}
                                                    admin={admin}
                                                    isHomeOrDestacada={true}
                                                    setDataModal={setModal}
                                                    isOpenModal={isOpenModal}
                                                    setIsOpenModal={setIsOpenModal}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className='mt-24 lg:hidden'>
                        <div className='flex flex-row'>
                            <div className='2/4'>
                                <div className='relative outline-none'>
                                    <div className='absolute text-left lg:left-60 left-10 mt-5'>
                                        <div className='flex flex-row'>
                                            <p className='text-xl text-white'>Outlet</p>
                                            <img src={arrow} className='h-6 ml-2 mt-1' />
                                        </div>
                                        <p className='text-lg text-white w-28'>descuentos y promociones especiales</p>
                                    </div>
                                    <img src={homeOutlet} alt="Home Outlet" className='w-[350px] object-cover h-[350px]' />
                                </div>
                            </div>
                            <div className='w-2/4'>
                                <Swiper
                                    spaceBetween={200}
                                    slidesPerView={1}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Autoplay, Pagination, Navigation]}

                                >
                                    {cards && cards.map((card) => (
                                        <SwiperSlide key={card.idproducto}>
                                            <div className=''>
                                                <Card
                                                    imageUrl={card.imagen}
                                                    nombre={card.nombre}
                                                    price={card.precio_pesos}
                                                    item={card}
                                                    label={'20% OFF'}
                                                    carrito={carrito}
                                                    setCarrito={setCarrito}
                                                    priceOffert={card.precio_oferta}
                                                    dolar={dolar}
                                                    isHome={true}
                                                    admin={admin}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className='flex lg:flex-row flex-col lg:mx-14 justify-center items-center leading-tight lg:justify-between lg:mt-24 lg:mb-20 mb-10 text-sm'>
                        <div className='flex flex-col w-32 items-center lg:mt-10'>
                            <img src={design} className='lg:w-14 w-20 mb-4' alt="" />
                            <div className=' lg:text-base text-[18px]'>
                                <p>Fabricamos</p>
                                <p className='font-semibold'>Diseños personalizados</p>
                            </div>
                        </div>
                        <div className='flex flex-col w-32 items-center mt-10'>
                            <img src={security} className='lg:w-14 w-20 mb-4' alt="" />
                            <div className=' lg:text-base text-[18px]'>
                                <p className='font-bold'>Compra segura</p>
                                <p>Guardamos tus datos con protocolos de seguridad</p>
                            </div>
                        </div>
                        <div className='flex flex-col w-32 items-center mt-10'>
                            <img src={cuotas} className='lg:w-14 w-20 mb-4' alt="" />
                            <div className='flex flex-col items-center lg:text-base text-[18px]'>
                                <div className='flex flex-row'>
                                    <p>Hasta</p>
                                    <p className='font-semibold ml-1' >6 cuotas</p>
                                </div>
                                <p>sin interés con tarjetas bancarias</p>
                            </div>
                        </div>
                        <div className='flex flex-col w-32 items-center mt-10'>
                            <img src={envios} className='lg:w-14 w-20 mb-4' alt="" />
                            <div className='items-center lg:text-base text-[18px]'>
                                <b>Envíos a todo el país</b> / Gratis en CABA a partir de $200.000 pesos
                            </div>
                        </div>
                        <div className='flex flex-col w-32 items-center mt-10'>
                            <img src={descuentos} className='lg:w-14 w-20 mb-4' alt="" />
                            <div className=' lg:text-base text-[18px]'>
                                <p className='font-semibold'>20% OFF</p>
                                <p>abonando con transferancia o con Efectivo</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='absolute lg:mt-60 mt-32 ml-5 lg:ml-20'>
                            <p className='text-[30px] leading-tight w-40 mb-5 text-left'>Entrega Inmediata</p>
                            <a href='/entrega-inmediata' className='border border-black text-[14px] px-10 font-semibold py-2 mt-2 rounded-sm'>VER PRODUCTOS</a>
                        </div>
                        <img src={entrgaLogo} alt="" className='lg:block hidden' />
                        <div className="relative -z-10 w-full h-[360px] overflow-hidden lg:hidden">
                            <img
                                src={entregaMobile}
                                alt=""
                                className="w-full h-full"
                            />
                        </div>
                    </div>
                    <FeaturedProduct isWhite={true} />
                </div>
            </div>}
            {isOpenModal && <Modal
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                item={modal.item}
                carrito={modal.carrito}
                setCarrito={modal.setCarrito}
                precioFinal={modal.precioFinal}
                precioConIncremento={modal.precioConIncremento}
                precioTachado={modal.precioTachado}
                porcentaje={modal.porcentaje}
            />}
        </div>
    );
}

export default Home;
