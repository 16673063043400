import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCarrito, getCartShop, getMoneyChange, getProductPage, getProductosRelacionados } from '../../redux/actions';
import card from '../../assets/black-card.svg';
import carrito1 from '../../assets/carrito_1.svg';
import box from '../../assets/__embalaje_1.svg';
import envios from '../../assets/__envios.svg';
import FeaturedProduct from '../../Components/FeaturedProduct/FeaturedProduct';
import Header from '../../Components/Header/Header';
import disenamos from '../../assets/_disenos.svg';
import HeaderLower from '../../Components/HeaderLower/HeaderLower';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import jwt from 'jsonwebtoken';
import Carrito from '../../Components/Carrito/Carrito';
import foltanteWhatsapp from '../../assets/flotante_whatsapp.svg';
import foltanteWhatsappHover from '../../assets/flotante_whatsapp_hover.svg';
import axios from 'axios';

const JWT_SECRET = 'tu_clave_secreta';

export default function DetallesProducto() {
    const { paramsSlug } = useParams();
    const slug = paramsSlug.split('-');
    const id = slug[slug.length - 1];
    const dispatch = useDispatch();
    const item = useSelector((state) => state.productPage);
    const producto = item[0];
    const [localCant, setLocalCant] = useState(1);
    const [carrito, setCarrito] = useState(false);
    const imagen = producto ? producto.imagen : null;
    const [cant, setCant] = useState(1);
    const [principal, setPrincipal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const atributos = producto ? JSON.parse(producto.atributos) : null;
    const [principalImage, setPrincipalImage] = useState(producto?.imagen);
    const [careusel, setCareusel] = useState([]);
    const careuselImage = producto ? JSON.parse(producto.imagenes) : null;
    const dolar = useSelector((state) => state.dolarPrice);
    const [posicion, setPosicion] = useState(-1);
    const modelosRelacionados = useSelector((state) => state.relacionados);
    const itemPriceUSD = producto && (Number(producto.precio_oferta_dolares) > 0 ? producto.precio_oferta_dolares : producto.precio_dolar);
    const token = localStorage.getItem('token');
    const [showImage, setShowImage] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [hoverWSP, setHoverWSP] = useState(false);

    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwt.verify(token, JWT_SECRET);
                setAdmin(decodedToken.permisos);
            } catch (error) {
                console.error('Token no válido o caducado:', error.message);
            }
        }

    }, [token]);

    useEffect(() => {
        console.log(producto)
        console.log(careusel)
        if (producto) {
            const careusel = JSON.parse(producto.imagenes);
            setCareusel([...careusel, { imagen: producto.imagen, orden: 0 }]);
        }
    }, [producto]);

    function calcularPorcentajeDiferencia(numero1, numero2) {
        const diferencia = Math.floor(numero1) - Math.floor(numero2);
        const porcentajeDiferencia = 100 - (numero1 * 100 / numero2);
        return porcentajeDiferencia;
    }

    const handleClickImage = () => {
        if (careusel === null || careusel.length === 1) return;
        if (posicion == careusel.length) {
            setPosicion(0);
        } else {
            setPosicion(posicion + 1);
        }
        console.log(posicion)
    };

    const precioTotalSinDescuento = producto && (producto.precio_dolar * dolar);
    const precioConIncremento = itemPriceUSD * dolar;
    const descuentoTotal = precioTotalSinDescuento * 0.20;
    const descuento = precioConIncremento * 0.20;

    const precioTotal = (precioTotalSinDescuento - descuentoTotal).toFixed(0);
    const precioFinal = (precioConIncremento - descuento).toFixed(0);
    const label = precioConIncremento > 0 ? `${(calcularPorcentajeDiferencia(itemPriceUSD, (producto && producto.precio_dolar)).toFixed(0))}% OFF` : '';

    const calculateInstallment = () => {
        const installmentPrice = precioTotalSinDescuento / 6;
        return installmentPrice.toFixed(2);
    };

    const objAddCart = {
        token,
        idproducto: producto?.idproducto,
        cantidad: localCant
    }

    const handleAddCart = async () => {
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/agregar-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objAddCart),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token))
    }

    const sillaDescripcion = atributos && atributos['Medidas'] && atributos['Medidas'].trim().replace(/^"|"$/g, '').split('-');

    const handleCantidad = async (suma) => {
        let updatedCant = localCant;

        if (suma === 'suma') {
            updatedCant = updatedCant < producto.cantidad ? Number(updatedCant) + 1 : producto.cantidad;
        } else if (suma === 'resta' && updatedCant > 1) {
            updatedCant -= 1;
        }

        setLocalCant(updatedCant);
    }

    const handleChange = async (e) => {
        const inputValue = e.target.value;
        let newLocalCant;

        if (!isNaN(inputValue) && inputValue > 0) {
            if (inputValue > producto.cantidad) {
                newLocalCant = producto.cantidad;
            } else {
                newLocalCant = inputValue;
            }
        } else {
            newLocalCant = 1;
        }

        setLocalCant(newLocalCant);
    }

    const changeImagePrincipal = (imagen) => {
        setPrincipalImage(imagen)
    }

    const handleBuy = async () => {
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/agregar-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objAddCart),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token))
        window.location.href = '/carrito';
    }

    const handleSelect = (e) => {
        window.location.href = `/producto/${e.target.value}`;
    }

    useEffect(() => {
        dispatch(getProductPage(id))
        dispatch(getProductosRelacionados(id))
    }, [dispatch]);

    const Magnifier = ({ src, zoomFactor = 2, onClick }) => {
        const [showZoom, setShowZoom] = useState(false);
        const [position, setPosition] = useState({ x: 0, y: 0 });
        const imageRef = useRef(null);

        const handleMouseMove = e => {
            const { left, top } = imageRef.current.getBoundingClientRect();
            setPosition({
                x: e.pageX - left - window.pageXOffset,
                y: e.pageY - top - window.pageYOffset,
            });
        };

        return (
            <div
                style={{ position: 'relative' }}
                onMouseEnter={() => setShowZoom(true)}
                onMouseLeave={() => setShowZoom(false)}
                onMouseMove={handleMouseMove}
                onClick={onClick}
            >
                <img ref={imageRef} src={src} alt="" style={{ width: '300%', height: 'auto' }} />
                {showZoom && (
                    <div
                        style={{
                            position: 'absolute',
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            width: '300px',
                            height: '300px',
                            borderRadius: '50%',
                            border: '2px solid #fff',
                            transform: 'translate(-50%, -50%)',
                            background: `url(${src}) no-repeat`,
                            backgroundSize: `${imageRef.current.offsetWidth * zoomFactor}px ${imageRef.current.offsetHeight * zoomFactor}px`,
                            backgroundPositionX: -position.x * zoomFactor + 50,
                            backgroundPositionY: -position.y * zoomFactor + 50,
                        }}
                    ></div>
                )}
            </div>
        );
    };


    return (
        <div className=''>
            <Header />
            <HeaderLower />
            <h1>holaaa</h1>
            {producto && Number(producto.precio_oferta_dolares) !== 0 && <div className='lg:hidden border-b-black border-b-2 mt-16 w-full h-10 bg-[#E40F1B] text-2xl text-white'>
                <p className='pt-1'>
                    {label}
                </p>
            </div>}
            {item && dolar && producto && atributos ?
                <div className='2xl:ml-[14%] 2xl:mr-[10%]'>
                    <div itemScope itemType="http://schema.org/Product">
                        <meta itemProp="productID" content={producto.idproducto} />
                        <meta itemProp="brand" content="Sentate Con Estilo" />
                        <meta itemProp="name" content={producto.nombre} />
                        <meta itemProp="description" content={producto.descripcion ? producto.descripcion.replace(/<\/?p>/g, "") : producto.nombre} />
                        <meta
                            itemProp="url"
                            content={`https://www.sentateconestilo.com.ar/producto/${producto.slug}`}
                        />
                        <meta
                            itemProp="image"
                            content={`https://sistema.depcsuite.com/productos/${producto.imagen}`}
                        />
                        <div
                            itemProp="offers"
                            itemScope
                            itemType="http://schema.org/Offer"
                        >
                            <link
                                itemProp="availability"
                                href="http://schema.org/InStock"
                            />
                            <link
                                itemProp="itemCondition"
                                href="http://schema.org/NewCondition"
                            />
                            <meta itemProp="price" content={precioFinal} />
                            <meta itemProp="priceCurrency" content="ARS" />
                        </div>
                    </div>
                    <div className='lg:mt-20 mt-14 lg:grid lg:grid-cols-2'>
                        {() => changeImagePrincipal(imagen)}
                        <div className='relative'>
                            {Number(producto.precio_oferta_dolares) !== 0 && <div className='absolute hidden lg:block lg:mt-5 ml-7 text-xl text-center items-center rounded-l-md lg:right-0 z-[9] text-white w-40 h-8 bg-[#E40F1B]'>
                                <p className='pt-0.5'>
                                    {label}
                                </p>
                            </div>}
                            <div className='lg:block hidden text-left text-[12px] font-medium ml-12'>
                                <p className='flex flex-row'><a href="/">Home</a><p className='mx-2'>{'>'}</p> Shop <p className='mx-2'>{'>'}</p><a href="/sillas">Sillas</a><p className='mx-2'>{'>'}</p> SubCategoría <p className='mx-2'>{'>'}</p>{producto.nombre.split(' ')[0]}</p>
                            </div>
                            {admin && <div className='absolute lg:right-10 top-20 z-[9]'>
                                <a target='_blank' href={`https://sistema.depcsuite.com/producto/productos/${producto.idproducto}`}>
                                    <img src={disenamos} className='items-center w-10' alt="" />
                                </a>
                            </div>}
                            {careusel !== null && careusel.length > 0 && careusel[posicion]?.imagen.endsWith('.mp4') ?
                                <video autoPlay={true} muted onClick={(e) => {
                                    e.stopPropagation();
                                    handleClickImage();
                                }} loop className='lg:flex hidden justify-center'>
                                    <source src={`https://sistema.depcsuite.com/productos/${careusel[posicion]?.imagen}`} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                : <div className='lg:flex hidden justify-center lg:ml-12 lg:mr-2'>
                                    <Magnifier onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickImage();
                                    }} src={`https://sistema.depcsuite.com/productos/${careusel !== null && careusel.length > 0 && careusel[posicion]?.imagen || imagen}`} />
                                </div>}
                            <div className='flex lg:hidden justify-center lg:ml-12 lg:mr-2'>
                                {careusel !== null && careusel.length > 0 && careusel[posicion]?.imagen.endsWith('.mp4') ? (
                                    <video autoPlay={true} muted onClick={(e) => {
                                        e.stopPropagation();
                                        handleClickImage();
                                    }} loop className='mb-10 cursor-pointer mr-auto ml-auto flex h-2/3 justify-center items-center'>
                                        <source src={`https://sistema.depcsuite.com/productos/${careusel[posicion]?.imagen}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleClickImage();
                                        }}
                                        src={`https://sistema.depcsuite.com/productos/${careusel !== null && careusel.length > 0 && careusel[posicion]?.imagen || imagen}`}
                                        className='mb-10 cursor-pointer mr-auto ml-auto flex h-2/3 justify-center items-center'
                                        alt=""
                                    />
                                )}
                            </div>
                            <div>
                                <div className='lg:grid hidden cursor-pointer grid-cols-2 items-center justify-center mt-7 ml-12'>
                                    {careusel !== null && careusel.length > 0 && careusel
                                        .sort((a, b) => a.orden - b.orden) // Ordenar el array por el número de orden
                                        .map((imagen, index) => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        setPrincipal(false);
                                                        setPosicion(index);
                                                        setShowImage(true);
                                                    }}
                                                    key={index}
                                                    className="mr-2 mb-2"
                                                >
                                                    {imagen.imagen.endsWith('.mp4') ? (
                                                        <video controls autoPlay={true} muted loop className="carousel-video">
                                                            <source
                                                                src={`https://sistema.depcsuite.com/productos/${imagen.imagen}`}
                                                                type="video/mp4"
                                                            />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    ) : (
                                                        <img
                                                            src={`https://sistema.depcsuite.com/productos/${imagen.imagen}`}
                                                            alt=""
                                                            className="carousel-image"
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}

                                </div>
                            </div>

                        </div>
                        <div className='lg:border-l-2 lg:mr-28 lg:mx-0 mx-3'>
                            <div className='text-left lg:ml-5'>
                                <p className='lg:text-[23px] text-[28px] lg:mt-5 font-semibold mb-4'>{producto.nombre}</p>
                                <div className="lg:text-[20px] text-[24px]">
                                    <div className='flex flex-col'>
                                        <label className='text-sm uppercase'>PRECIO 20% OFF con transferencia:</label>
                                        <div className='flex lg:flex-row flex-col'>
                                            <span className="mr-2 font-semibold">{Number(precioFinal) !== 0 || Number(producto.cantidad) != 0 ? Number(precioFinal).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            }) : 'AGOTADO'}</span>
                                            <span className="mr-2 line-through opacity-70">{Number(producto.precio_oferta_dolares) !== 0 ? Number(precioTotal).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            }) : ''}</span>
                                        </div>
                                        <label className='text-sm mt-1'>PRECIO DE LISTA:</label>
                                        <div className='flex lg:flex-row flex-col'>
                                            <span className="mr-2 font-semibold">{Number(precioConIncremento) !== 0 || Number(producto.cantidad) != 0 ? Number(precioTotalSinDescuento).toLocaleString("es-AR", {
                                                style: "currency",
                                                currency: "ARS",
                                                maximumFractionDigits: 0,
                                            }) : 'AGOTADO'}</span>
                                        </div>
                                    </div>
                                </div>
                                {Number(precioFinal) !== 0 && Number(producto.cantidad) != 0 && <div className="flex flex-row text-left opacity-60">
                                    <img src={card} alt="Icono" className="w-4" />
                                    <span className="text-[14px] ml-1">6 cuotas de </span>
                                    <span className="ml-1 text-[14px]">{Number(calculateInstallment().split('.')[0]).toLocaleString("es-AR", {
                                        style: "currency",
                                        currency: "ARS",
                                        maximumFractionDigits: 0,
                                    })}</span>
                                    <span className="text-[11px] ml-[2px]">{calculateInstallment().split('.')[1]}</span>
                                </div>}
                                <div className='border-t-2  my-5'></div>
                                <div>
                                    <p className='text-[12px] opacity-90 mb-2' >Modelos disponibles:</p>
                                    <select onChange={handleSelect} value='Rojo' className='p-2 rounded-md text-[14px] lg:w-1/2 w-full h-10 border' id="">
                                        <option value="Rojo">
                                            {producto.nombre}
                                        </option>
                                        {modelosRelacionados && modelosRelacionados.map((modelo) => {
                                            return (
                                                <option key={modelo.idproducto} value={modelo.idproducto}>{modelo.nombre}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className='flex flex-row'>
                                    <div className='mt-5 flex lg:flex-col flex-row opacity-90 lg:items-start items-center'>
                                        <p className='text-[12px] mr-4'>Cantidad:</p>
                                        <div className='flex flex-row border rounded-md mt-1'>
                                            <button className='font-semibold opacity-50 lg:w-8 w-10 lg:pt-0 pt-2 h-10 lg:h-8' onClick={() => handleCantidad('resta')}>-</button>
                                            <input type="text" className='lg:w-14 lg:h-8 w-24 h-12 lg:pl-6 pl-10' value={localCant} onChange={(e) => handleChange(e)} name="" id="" />
                                            <button className='font-semibold opacity-50 lg:w-8 w-10 lg:pt-0 pt-2.5 h-10 lg:h-8' onClick={() => handleCantidad('suma')}>+</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='border-t-2 my-5 '></div>
                                <div className='flex flex-row uppercase'>
                                    <button
                                        onClick={() => handleBuy()}
                                        disabled={Number(precioFinal) == 0 || Number(producto.cantidad) == 0}
                                        className={`border uppercase text-sm font-bold border-black ${Number(precioFinal) == 0 || Number(producto.cantidad) == 0 ? 'cursor-not-allowed' : ''} text-white w-1/2 hover:border-[#E40F1B] hover:bg-[#E40F1B] rounded-sm bg-black py-[5.3px]`}
                                    >
                                        Comprar ahora
                                    </button>
                                    {Number(precioFinal) !== 0 && Number(producto.cantidad) > 0 &&
                                        <button onMouseEnter={() => setShowTooltip(true)}
                                            onMouseLeave={() => setShowTooltip(false)} onClick={() => {
                                                handleAddCart();
                                                setCarrito(true);
                                            }} className='flex border uppercase text-sm font-bold hover:bg-white hover:border-black border-[#E6E6EB] bg-[#E6E6EB] rounded-sm w-1/2 ml-[20px] justify-center items-center h-9'>
                                            <p className=''>Agregar al carrito</p>
                                        </button>}
                                </div>
                                {atributos['Tipo de envío'] && <div className='border-y-2 mt-5 text-center my-3 border-[#E40F1B]'>
                                    <p className='my-2 text-[#E40F1B] font-semibold text-[12px] uppercase'>{atributos['Tipo de envío']}</p>
                                </div>}
                                {!atributos['Tipo de envío'] && producto.categorias.includes('ENTREGA INMEDIATA') && <div className='border-y-2 mt-5 text-center my-3 border-[#E40F1B]'>
                                    <p className='my-2 text-[#E40F1B] font-semibold text-[12px] uppercase'>Disponible para entrega inmediata</p>
                                </div>}
                                <div className='text-[11px] font-semibold'>
                                    <div className='flex flex-row my-3'>
                                        <img src={envios} alt="" />
                                        <div className='mt-1.5 ml-2'>
                                            <div className='flex flex-row'>
                                                <p className='font-extrabold mr-1'>Envío gratis en CABA</p>
                                                <p>a partir de $200.000</p>
                                            </div>
                                            <p>Envíos a todo el país por expreso a cargo
                                                del comprador
                                            </p>
                                            <a className='border-b-[0.5px]' target='_blank' href='/atencion-al-cliente/politicas-envio'>Ver políticas</a>
                                        </div>
                                    </div>
                                    {atributos.Embalaje && <div className='flex flex-row'>
                                        <img src={box} alt="" />
                                        <p className='mt-4 ml-2'>{atributos.Embalaje}</p>
                                    </div>}
                                </div>
                                <div className=''>
                                    <div className='border-t-2 my-5'></div>
                                    <p className='font-semibold'>Descripción de producto</p>
                                    <div className='text-[12px]'>
                                        <div dangerouslySetInnerHTML={{ __html: producto.descripcion }} />
                                        <p className='mt-3 font-semibold'>Modelo: {producto.nombre.split(' ')[0]}</p>
                                        <p>Material: {atributos.Material}</p>
                                        <p>Color: {atributos.Color}</p>
                                        <p className='mt-2 font-semibold'>Cuidados</p>
                                        <p>Limpieza: {atributos.Limpieza}</p>
                                        <p>{atributos["Apto. exterior"]}</p>
                                        <p className='mt-3 font-semibold'>Dimensiones:</p>
                                        {
                                            sillaDescripcion?.map((medida, index) => {
                                                return (
                                                    <p key={index}>{medida}</p>
                                                )
                                            })
                                        }
                                        <p>Peso: {atributos.Peso}</p>
                                        <p className='mt-3 font-semibold'>Otras características:</p>
                                        <p>• Resistencia {atributos["Resistencia en peso "]}</p>
                                        <p>• Apilable {atributos.Apilable}</p>
                                        <p>• {atributos.Regatones === 'Si' ? 'Posee regatones' : 'No posee regatones'}</p>
                                        <p>• Pieza/s {atributos.Piezas}</p>
                                        <p className='mt-3 font-semibold'>Origen {atributos.Origen}</p>
                                        <p>Garantía: {atributos['Garantía']}</p>
                                        <div className='border-t-2 my-7'></div>
                                        <p className='mb-5'>Sentate Con Estilo se esfuerza por ser preciso en cuanto al color de los productos en sus fotografías.
                                            pero puede haber alguna diferencia en el tono debido a la luz, la cámara o la pantalla del dispositivo utilizado para ver las imagenes.
                                        </p>
                                        <div className='border-t-2 my-7'></div>
                                        <p className='mb-3'>Trabajamos con productos importados de excelente calidad que son réplicas de diseños originales y también, con productos de fabricación nacional que son realizados artesanalmente. Nos esmeramos en ofrecer la mejor calidad en las terminaciones y entendemos que ningún producto es completamente perfecto.</p>
                                        <p>Te ofrecemos la posibilidad de visitar nuestro depósito en Barracas (CABA) para que puedas ver todos nuestros productos personalmente y resolver cualquier duda o consulta que puedas tener antes de realizar tu compra. ¡Agendá tu visita y te esperamos!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="flex justify-center items-center mt-60 mb-60">
                    <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#E40F1B]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
            }
            <div className='mt-16'></div>
            {!hoverWSP ? <button onMouseEnter={() => setHoverWSP(true)} className='fixed bottom-0 right-5 z-[1000]'>
                <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                    <img src={foltanteWhatsapp} className='mb-5' alt="" />
                </a>
            </button> :
                <button onMouseLeave={() => setHoverWSP(false)} className='fixed bottom-0 right-5 z-[1000]'>
                    <a target='_blank' href='https://api.whatsapp.com/send?phone=541132619277'>
                        <img src={foltanteWhatsappHover} className='mb-5' alt="" />
                    </a>
                </button>
            }
            <FeaturedProduct />
            {carrito &&
                <Carrito setIsOpen={setCarrito} isOpen={carrito} />
            }
        </div>
    );
}
