import React, { useState } from 'react';
import trash from "../../assets/trash.svg";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';

const CardCarrito = ({ item, dolar, setLoading }) => {
    const [localCant, setLocalCant] = useState(item.cantidad);
    const token = localStorage.getItem("token");
    const dispatch = useDispatch();

    const handleCantidad = async (suma, idproducto) => {
        let updatedCant = localCant;

        if (suma === 'suma') {
            updatedCant = updatedCant < item.cantidadTotal ? Number(updatedCant) + 1 : item.cantidadTotal;
        } else if (suma === 'resta' && updatedCant > 1) {
            updatedCant -= 1;
        }

        setLocalCant(updatedCant);

        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto, cantidad: updatedCant }),
            isBase64Encoded: false,
        });

        dispatch(getCarrito(token));
    }


    const handleChange = async (e, idproducto) => {
        const inputValue = e.target.value;
        let newLocalCant;

        if (!isNaN(inputValue) && inputValue > 0) {
            if (inputValue > item.cantidadTotal) {
                newLocalCant = item.cantidadTotal;
            } else {
                newLocalCant = inputValue;
            }
        } else {
            newLocalCant = 1;
        }

        setLocalCant(newLocalCant);

        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/cambiar-cantidad`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto, cantidad: newLocalCant }),
            isBase64Encoded: false,
        });

        dispatch(getCarrito(token));
    }

    const itemPriceUSD = item.precio_dolar;
    const itemPriceUSDOffert = item.precio_oferta_dolares;
    const precioConIncremento = itemPriceUSD * dolar;
    const precioConIncrementoOferta = itemPriceUSDOffert * dolar;
    const descuentoOferta = precioConIncrementoOferta;
    const descuento = precioConIncremento;
    const precioOferta = ((precioConIncrementoOferta || descuentoOferta) * localCant).toFixed(0);
    const precioFinal = ((precioConIncremento || descuento) * localCant).toFixed(0);

    const handleClick = async (idproducto) => {
        setLoading(false);
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/borrar-producto-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto }),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token));
        setLoading(true);
    }
    return (
        <div className="flex flex-row">
            <img
                src={`https://sistema.depcsuite.com/productos/${item.imagen}`}
                alt={item.nombre}
                className='w-24 border'
            />
            <div className="ml-3 w-full">
                <h5 className="font-semibold text-[12px] w-[100%] mb-6 text-left">{item.nombre}</h5>
                <div className='border w-20'>
                    <button className='font-semibold h-4' onClick={() => handleCantidad('resta', item.idproducto)}>-</button>
                    <input type="text" onChange={(e) => handleChange(e, item.idproducto)} className='h-4 pl-4 w-10' value={localCant} name="" id="" />
                    <button className='font-semibold h-4' onClick={() => handleCantidad('suma', item.idproducto)}>+</button>
                </div>
                <div className='flex flex-row text-[12px] justify-between mt-2.5'>
                    <div>
                        <span className="mr-2 text-[#E40F1B] font-semibold">{Number(precioFinal).toLocaleString("es-AR", {
                            style: "currency",
                            currency: "ARS",
                            maximumFractionDigits: 0,
                        })}</span>
                    </div>
                    <button onClick={() => handleClick(item.idproducto)}>
                        <img src={trash} className='w-4' alt="" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CardCarrito;
