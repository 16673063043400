import React, { useEffect, useState } from 'react';
import trash from "../../../../assets/trash.svg";
import useLocalStorage from '../../../../utils/useLocalStorage/useLocalStorage';
import { useDispatch } from 'react-redux';
import { getCarrito, getMoneyChange } from '../../../../redux/actions';
import axios from 'axios';

const CardDatos = ({ item, dolar, setLoading, medioPago }) => {
    const cant = item.cantidad;
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    useEffect(() => {
        let timer;

        timer = setTimeout(() => {
            setLoading(true);
        }, 1500);

        return () => clearTimeout(timer);
    }, []);

    const itemPriceUSD = item.precio_dolar;
    const precioConIncremento = itemPriceUSD * dolar;
    const descuento = medioPago && medioPago !== '20' ? (precioConIncremento * 0.20) : 0;

    const precioFinal = ((precioConIncremento - descuento) * item.cantidad).toFixed(0);

    const handleClick = async (idproducto) => {
        setLoading(false);
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/borrar-producto-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({ token, idproducto }),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token, true));
        setLoading(true);
    }

    return (
        <div className="my-1">
            <div className='flex flex-row justify-between items-center pb-5 h-16 text-sm'>
                <div>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${item.imagen}`}
                        alt={item.nombre}
                        className='lg:w-16 w-16 mt-5 border'
                    />
                </div>
                <div className='flex items-center justify-center'>
                    <p className='mx-3 mt-5'>{cant}</p>
                </div>
                <div className='flex flex-row'>
                    <h5 className="lg:font-semibold lg:text-base text-sm lg:w-60 mt-5 text-left">{item.nombre}</h5>
                </div>
                <div className="lg:mx-10">
                    <div className='flex flex-row mt-5'>
                        <div className='flex flex-row lg:w-40'>
                            <p className='mr-2 lg:block hidden'>Precio: </p>
                            <span className="font-semibold">{Number(precioFinal).toLocaleString('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                maximumFractionDigits: 0,
                            })}.-</span>
                        </div>
                        <button className='lg:block hidden' onClick={() => handleClick(item.idproducto)}>
                            <img src={trash} className='w-4 ml-3' alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardDatos;