import React, { useState, useEffect } from 'react';
import './Card.css';
import card from '../../assets/black-card.svg';
import carrito1 from '../../assets/carrito_1.svg';
import carritoEnough from '../../assets/carrito_Enough.svg';
import carrito2 from '../../assets/carrito_2.svg';
import favoriteFull from '../../assets/fav_full.svg';
import ver from '../../assets/ver.svg';
import ver1 from '../../assets/no-ver.svg';
import Modal from '../../ui/Modal/Modal';
import disenamos from '../../assets/disenamos.svg';
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';
import favoriteIcon from '../../assets/fav_1.svg';
import axios from 'axios';

export default function Card({ nombre, carrito, setCarrito, price, imageUrl, item, priceOffert, favorite = false, dolar, isHome, deleteFav, admin, carritoLocale, isHomeOrDestacada, setDataModal, setIsOpenModal, isOpenModal }) {
    const token = localStorage.getItem('token');
    const [isHovered, setIsHovered] = useState(deleteFav ? 'on' : 'off');
    const [modal, setModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showVer, setShowVer] = useState(false);
    const [showFav, setShowFav] = useState(false);
    const [showCarrito, setShowCarrito] = useState(false);
    const dispatch = useDispatch();

    function calcularPorcentajeDiferencia(numero1, numero2) {
        const porcentajeDiferencia = 100 - (numero1 * 100 / numero2);
        return porcentajeDiferencia;
    }


    const isHoveredHandler = (hover) => {
        if (hover === 'enter' || hover === 'on') {
            setIsHovered('on');
        } else {
            setIsHovered('off');
        }
    };


    const itemPriceUSD = item.precio_dolar;
    const itemPriceUSDOffert = item.precio_oferta_dolares;
    const precioConIncremento = itemPriceUSD * dolar;
    const precioOfertaIncremento = itemPriceUSDOffert * dolar;
    const descuentoOferta = precioOfertaIncremento * 0.20;
    const descuento = precioConIncremento * 0.20;

    const precioFinal = (precioConIncremento - descuento).toFixed(0);
    const precioOferta = Number(itemPriceUSDOffert) > 0 ? (precioOfertaIncremento - descuentoOferta).toFixed(0) : 0;

    const calculateInstallment = () => {
        const installmentPrice = precioConIncremento / 6;
        return installmentPrice.toFixed(2);
    };

    const label = precioOfertaIncremento > 0 ? `${Math.abs((calcularPorcentajeDiferencia(itemPriceUSDOffert, itemPriceUSD)).toFixed(0))}% OFF` : '';

    const objFav = {
        token,
        idproducto: item.idproducto,
        isDelete: false
    }

    const objFavDelete = {
        token,
        idproducto: item.idproducto,
        isDelete: true
    }

    const objAddCart = {
        token,
        idproducto: item.idproducto,
        cantidad: 1
    }

    const handleAddCart = async () => {
        if (!token) return window.location.href = '/ingresar';
        await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/agregar-carrito`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objAddCart),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token))
    }

    const addFavorite = async () => {
        if (!token) return window.location.href = '/ingresar';
        const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce-agregar-favoritos`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objFav),
            isBase64Encoded: false,
        });
        const carritoData = JSON.parse(response.data.body);
        if (carritoData.error) {
            localStorage.removeItem('token');
            window.location.href = '/ingresar';
        }
    }

    const deleteFavorite = async () => {
        const response = await axios.post(`https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce-agregar-favoritos`, {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify(objFavDelete),
            isBase64Encoded: false,
        });
        const carritoData = JSON.parse(response.data.body);
        if (carritoData.error) {
            localStorage.removeItem('token');
            window.location.href = '/ingresar';
        }
    }
    const [precio, decimal] = calculateInstallment().split('.');

    const handleSeeModal = () => {
        if (isHomeOrDestacada) {
            setIsOpenModal(true);
            setDataModal({
                isOpen: isOpenModal,
                setIsOpen: setIsOpenModal,
                item: item,
                carrito: carrito,
                setCarrito: setCarrito,
                precioFinal: Number(Number(itemPriceUSDOffert) !== 0 ? precioOferta : precioFinal),
                precioConIncremento: precioConIncremento,
                porcentaje: precioOfertaIncremento > 0 ? `${Math.abs((calcularPorcentajeDiferencia(itemPriceUSDOffert, itemPriceUSD)).toFixed(0))}% OFF` : '',
                precioTachado: Number(itemPriceUSDOffert) !== 0 && Number(precioFinal)
            });
        } else {
            setModal(true);
        }
    }

    return (
        <div
            className={`relative lg:w-[309px] w-[187px] mb-20 rounded-[4px] ${favorite ? 'h-72' : 'lg:h-[401px] h-[363.25px]'} ${showTooltip ? 'hovered' : ''}`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            <div className={`flex ${label ? 'justify-end' : ''}`}>
                {label && Number(precioFinal) !== 0 && Number(item.cantidad) != 0 && (
                    <div className="absolute top-5 bg-[#E40F1B] text-sm rounded-l-md text-white px-3">{label}</div>
                )}
                <a href={`/producto/${item.slug ? item.slug : ''}${item.slug ? '-' : ''}${item.idproducto}`}>
                    <img
                        src={`https://sistema.depcsuite.com/productos/${imageUrl}`}
                        alt="Producto"
                        className={`${favorite ? "w-60" : `${isHome ? 'w-72 lg:mr-3' : 'w-[309px]'}`} ${isHome ? 'lg:mr-2' : ''}`}
                    />
                </a>
                <div className={(isHovered == 'on' || showTooltip) && `hidden lg:block absolute ${label ? 'mr-8 bottom-28' : ''} items-center ml-10 ${!favorite ? "bottom-28" : "bottom-10"}`}>
                    <div className={(isHovered == 'on' || showTooltip) && `flex flex-row ${admin ? 'gap-4' : 'gap-10'} justify-between`}>
                        {showTooltip &&
                            <div
                                onMouseEnter={() => setShowVer(true)}
                                onMouseLeave={() => setShowVer(false)}
                            >
                                {showVer ?
                                    <button className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                                        <img src={ver} className='items-center' onClick={() => handleSeeModal()} alt="" />
                                    </button>
                                    :
                                    <button className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                                        <img src={ver1} className='items-center' onClick={() => handleSeeModal()} alt="" />
                                    </button>
                                }
                            </div>
                        }
                        {showTooltip &&
                            <div>
                                {(Number(precioFinal) !== 0 && Number(item.cantidad) != 0) ?
                                    <button
                                        onClick={() => {
                                            handleAddCart();
                                            setCarrito(true);
                                        }}
                                        className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl"
                                        onMouseEnter={() => setShowCarrito(true)}
                                        onMouseLeave={() => setShowCarrito(false)}
                                    >
                                        {!showCarrito ?
                                            <img src={carrito1} className='items-center' alt="" />
                                            :
                                            <img src={carrito2} className='items-center' alt="" />
                                        }
                                    </button> :
                                    <div
                                        className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl"
                                        onMouseEnter={() => setShowCarrito(true)}
                                        onMouseLeave={() => setShowCarrito(false)}
                                    >
                                        <img src={carritoEnough} className='items-center' alt="" />
                                    </div>
                                }
                            </div>
                        }
                        {(isHovered == 'on' || showTooltip) &&
                            <div className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                                {isHovered === 'on' ?
                                    <button onClick={() => {
                                        deleteFavorite();
                                        isHoveredHandler('off')
                                    }}
                                    >
                                        <img src={favoriteFull} className='items-center' alt="" />
                                    </button>
                                    :
                                    <button onClick={() => {
                                        addFavorite()
                                        isHoveredHandler('on')
                                    }}
                                        onMouseEnter={() => setShowFav(true)}
                                        onMouseLeave={() => setShowFav(false)}
                                    >
                                        {!showFav ?
                                            <img src={favoriteIcon} className='items-center' alt="" />
                                            :
                                            <img src={favoriteFull} className='items-center' alt="" />
                                        }
                                    </button>
                                }
                            </div>
                        }
                        {showTooltip && admin &&
                            <div className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                                <a target='_blank' href={`https://sistema.depcsuite.com/producto/productos/${item.idproducto}`}>
                                    <img src={disenamos} className='items-center' alt="" />
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {!favorite && <div className={`flex text-center flex-col lg:text-center lg:ml-0 ml-5 ${label ? '' : ''}`}>
                <div className={`text-sm font-mediumQuicksand flex justify-center items-center px-5 text-center font-semibold ${label ? '' : ''}`}>{nombre}</div>
                <div className="text-sm">
                    <div className="mt-[10px] font-bold Quicksand text-xl">
                        {Number(precioFinal) !== 0 && Number(item.cantidad) !== 0 ? (
                            <div className="flex flex-col items-center">
                                <span className='leading-tight'>
                                    {Number(Number(itemPriceUSDOffert) !== 0 ? precioOferta : precioFinal).toLocaleString("es-AR", {
                                        style: "currency",
                                        currency: "ARS",
                                        maximumFractionDigits: 0,
                                    })}
                                </span>
                                <strong className='text-xs'>20% OFF con transferencia</strong>
                            </div>
                        ) : (
                            <div className='text-sm'>
                                AGOTADO
                            </div>
                        )}
                    </div>

                    {<span className="opacity-90 line-through">{Number(itemPriceUSDOffert) !== 0 && Number(precioFinal).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    })}</span>}
                </div>
                <div className="text-sm font-regularQuicksand mt-2">
                    <span className="mr-2 ">{Number(precioFinal) !== 0 && Number(item.cantidad) != 0 ? Number(precioConIncremento).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    }) + ' de lista' : ''}</span>
                </div>
                {Number(precioFinal) !== 0 && Number(item.cantidad) != 0 && <div className="flex font-mediumQuicksand mt-[11px] flex-row lg:justify-center">
                    <img src={card} alt="Icono" className="w-4" />
                    <span className="text-[12px] ml-1">6 cuotas de </span>
                    <span className="ml-1 text-[11px]">{Number(precio).toLocaleString("es-AR", {
                        style: "currency",
                        currency: "ARS",
                        maximumFractionDigits: 0,
                    })}</span>
                    <span className="text-[7px] mt-[4.4px] ml-[2px]">{decimal}</span>
                </div>}
            </div>}
            {!isHomeOrDestacada && <Modal
                isOpen={modal}
                setIsOpen={setModal}
                item={item}
                carrito={carrito}
                setCarrito={setCarrito}
                precioFinal={Number(Number(itemPriceUSDOffert) !== 0 ? precioOferta : precioFinal)}
                precioTachado={Number(itemPriceUSDOffert) !== 0 && Number(precioFinal)}
                porcentaje={precioOfertaIncremento > 0 ? `${Math.abs((calcularPorcentajeDiferencia(itemPriceUSDOffert, itemPriceUSD)).toFixed(0))}% OFF` : ''}
                precioConIncremento={precioConIncremento}
            />}
        </div>
    );
}
